import 'core-js';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import YFRouter from './YFRouter.jsx';
import './styles/main.scss'



import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'; // 引入语言包
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn'); // 注意这里设置 moment 必须放在有 import 的后面。


ReactDOM.render(


  <ConfigProvider locale={zhCN}>
    <YFRouter />
  </ConfigProvider>
  ,
  document.getElementById('root')
);


