// import React from 'react'
// import { HashRouter, Route } from 'react-router-dom'
// import LoginPage from './pages/login/LoginPage.tsx'
// import MainPage from './pages/mainmanage/MainPage.tsx'
// import FadingRoute from './components/FadingRoute.jsx'
// import PersonReservePage from './pages/reservation/door/PersonReservePage.tsx'

// const YFRouter = () => {
//   return (
//     <HashRouter >
//       <Route path="/" exact component={LoginPage}></Route>
//       <Route path="/account/login" exact component={LoginPage}></Route>
//       <Route path="/acount/login/:type/" exact component={LoginPage}></Route>
//       <FadingRoute path="/main" component={MainPage} />
//       <Route path="/personreserve/:gardenId/" exact component={PersonReservePage}></Route>
//     </HashRouter >
//   )
// }

// export default YFRouter

import React from 'react'
import { HashRouter, Route } from 'react-router-dom'
import asyncComponent from './AsyncComponent'

const PmcLoginPage = asyncComponent(() => import('./pages/login/PmcLoginPage.tsx'))
const IspLoginPage = asyncComponent(() => import('./pages/login/IspLoginPage.tsx'))
const MchLoginPage = asyncComponent(() => import('./pages/login/MerchantLoginPage.tsx'))
const CarVideoLoginPage = asyncComponent(() => import('./pages/login/CarVideoLoginPage.tsx'))
const UploadLoginPage = asyncComponent(() => import('./pages/login/UploadLoginPage.tsx'))
const YopiLoginPage = asyncComponent(() => import('./pages/login/YopiLoginPage.tsx'))

const FadingRoutePmc = asyncComponent(() => import('./components/FadingRoute_Pmc.jsx'))
const FadingRouteISP = asyncComponent(() => import('./components/FadingRoute_ISP.jsx'))
const FadingRouteMch = asyncComponent(() => import('./components/FadingRoute_Merchant.jsx'))
const FadingRouteCarVideo = asyncComponent(() => import('./components/FadingRoute_CarVideo.jsx'))
const FadingRouteUpload = asyncComponent(() => import('./components/FadingRoute_Upload.jsx'))
const FadingRouteYopi = asyncComponent(() => import('./components/FadingRoute_Yopi.jsx'))


const PersonReservePage = asyncComponent(() => import('./pages/reservation/door/PersonReservePage.tsx'))
const LaneTollMainPage = asyncComponent(() => import('./pages/cartoll/lanetoll/LaneTollMainPage.tsx'))
const YardTollMainPage = asyncComponent(() => import('./pages/cartoll/yardtoll/YardTollMainPage.tsx'))
const CarMchTollMainPage = asyncComponent(() => import('./pages/cartoll/mchtoll/CarMchTollMainPage'))
const CarQrCodeViaMainPage = asyncComponent(() => import('./pages/carqrvia/CarQrCodeViaMainPage.tsx'))//手动输入车号
const CarTicketSelfReceiveMainPage = asyncComponent(() => import('./pages/carticketselfreceive/CarTicketSelfReceiveMainPage'))//自助领取减免券
const CarReduceSelfReceiveMainPage = asyncComponent(() => import('./pages/carreduceselfreceive/CarReduceSelfReceiveMainPage'))//自助领取减免券
const CarReduceApplyMainPage = asyncComponent(() => import('./pages/carreduceapply/CarReduceApplyMainPage.tsx'))
const IspPage = asyncComponent(() => import('./pages/isp/IspPage.tsx'))//服务商
const MainPage = asyncComponent(() => import('./pages/mainmanage/MainPage.tsx'))//物业
const CarMerchantMainPage = asyncComponent(() => import('./pages/carmerchantns/CarMerchantMainPage.tsx'))//商户
const CarVideoMainPage = asyncComponent(() => import('./pages/carvideo/CarVideoMainPage.tsx'))//车场监控
const UploadMainPage = asyncComponent(() => import('./pages/upload/UploadMainPage.tsx'))//数据上传
const YopiMainPage = asyncComponent(() => import('./pages/yopi/YopiMainPage.tsx'))//控制板
const WeiXinPayTicket = asyncComponent(() => import('./pages/cartoll/WeiXinPayTicket.tsx'))//微信支付小票

const YFRouter = () => {
  return (
    <HashRouter>
      <Route path="/" exact component={PmcLoginPage}></Route>
      <Route path="/pmc/login/" exact component={PmcLoginPage}></Route>
      <Route path="/isp/login/" exact component={IspLoginPage}></Route>
      <Route path="/carmch/login/" exact component={MchLoginPage}></Route>
      <Route path="/carvideo/login" exact component={CarVideoLoginPage}></Route>
      <Route path="/upload/login" exact component={UploadLoginPage}></Route>
      <Route path="/yopi/login" exact component={YopiLoginPage}></Route>

      <Route path="/personreserve/:gardenId/" exact component={PersonReservePage}></Route>
      <Route path="/clt/:gardenId/" component={LaneTollMainPage}></Route>
      <Route path="/cyt/:gardenId/" component={YardTollMainPage}></Route>
      <Route path="/cra/:gardenId/" component={CarReduceApplyMainPage}></Route>
      <Route path="/cmcht/:gardenId/:mchId/" component={CarMchTollMainPage}></Route>
      <Route path="/cqrvia/:gardenId/:laneId/" component={CarQrCodeViaMainPage}></Route>
      <Route path="/ctsr/:gardenId/:mchId/:issueId/" component={CarTicketSelfReceiveMainPage}></Route>
      <Route path="/crsr/:gardenId/:mchId/:type/" component={CarReduceSelfReceiveMainPage}></Route>
      <Route path="/wxpayticket" component={WeiXinPayTicket}></Route>




      <FadingRoutePmc path="/main" component={MainPage} />
      <FadingRouteISP path="/isp" component={IspPage} />
      <FadingRouteMch path="/carmch" component={CarMerchantMainPage} />
      <FadingRouteCarVideo path="/carvideo" component={CarVideoMainPage} />
      <FadingRouteUpload path="/upload" component={UploadMainPage} />
      <FadingRouteYopi path="/yopi" component={YopiMainPage} />


    </HashRouter>
  )
}

export default YFRouter

